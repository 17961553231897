import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from 'src/hooks/useAuthentication';
import Loadable from 'src/components/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('src/components/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('src/components/BlankLayout')));

const EntityAdd = Loadable(lazy(() => import('src/views/masters/entity/CategoryAdd')));
const EntityList = Loadable(lazy(() => import('src/views/masters/entity/CategoryList')));

// authentication
const Login = Loadable(lazy(() => import('src/views/authentication/auth1/Login')));
const Register = Loadable(lazy(() => import('src/views/authentication/auth1/Register')));
const ForgotPassword = Loadable(
  lazy(() => import('src/views/authentication/auth1/ForgotPassword')),
);

const TwoSteps = Loadable(lazy(() => import('src/views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('src/views/authentication/auth1/TwoSteps2')));
const Error = Loadable(lazy(() => import('src/views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('src/views/authentication/Maintenance')));

// MASTER USER PAGES
const MasterUserList = Loadable(lazy(() => import('src/views/masters/user/List')));
const MasterUserView = Loadable(lazy(() => import('src/views/masters/user/View')));

const Config = Loadable(lazy(() => import('../views/config/Config')));

// Lectures Route
const LecturesList = Loadable(lazy(() => import('../../src/views/masters/lectures/LecturesList')));
const LecturesAdd = Loadable(lazy(() => import('../../src/views/masters/lectures/LecturesAdd')));

// auth
const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = useAuthentication();
  return isAuthenticated ? element : <Navigate to="/auth/login" replace />;
};

// reports
const PaymentList = Loadable(lazy(() => import('../../src/views/reports/payment/PaymentList.jsx')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute element={<MasterUserList />} />,
      },

      {
        path: '/dashboard',
        exact: true,
        element: <ProtectedRoute element={<MasterUserList />} />,
      },
      {
        path: 'masters/category/add',
        exact: true,
        element: <ProtectedRoute element={<EntityAdd />} />,
      },
      {
        path: 'masters/category/list',
        exact: true,
        element: <ProtectedRoute element={<EntityList />} />,
      },
      {
        path: 'masters/category/edit/:id',
        exact: true,
        element: <ProtectedRoute element={<EntityAdd />} />,
      },
      {
        path: '/masters/lectures/list',
        exact: true,
        element: <ProtectedRoute element={<LecturesList />} />,
      },
      {
        path: 'masters/lectures/add',
        exact: true,
        element: <ProtectedRoute element={<LecturesAdd />} />,
      },
      {
        path: 'masters/lectures/edit/:ID',
        exact: true,
        element: <ProtectedRoute element={<LecturesAdd />} />,
      },
      {
        path: '/masters/user/list',
        exact: true,
        element: <ProtectedRoute element={<MasterUserList />} />,
      },
      {
        path: '/masters/user/view/:id',
        exact: true,
        element: <ProtectedRoute element={<MasterUserView />} />,
      },
      {
        path: '/masters/category/list',
        exact: true,
        element: <ProtectedRoute element={<EntityList />} />,
      },
      {
        path: '/masters/category/add',
        exact: true,
        element: <ProtectedRoute element={<EntityAdd />} />,
      },
      {
        path: '/reports/payment-list/list',
        exact: true,
        element: <ProtectedRoute element={<PaymentList />} />,
      },
      {
        path: '/config',
        exact: true,
        element: <ProtectedRoute element={<Config />} />,
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/two-steps/:userEmail', element: <TwoSteps /> },
      { path: '/auth/two-steps2/:userEmail', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
